.nav {
	display: flex;
	justify-content: flex-end;
}

.button {
  color: black;
  margin: 1rem;
  display: inline;

}

.button:hover {
  opacity: 0.6
}
