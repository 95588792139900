.image-container {
	text-align: center;
}

.image {
	margin-top: 2rem;
}

.image img {
	width: 500px;
	height: auto; 
	display: inline;
}