.title {
	text-align: center;
    font-family: monospace, sans-serif;
    margin-bottom: 3rem;
}

.p1 {
    font-weight: bold;
    display: inline;
}

.p2 {
    font-weight: bold;
    font-size: 5rem;
    color: red;
    display: inline;
}